<template>
  <div class="first-content" :style="{ width: `${width}px` }">
    <Title :icon="icon" :title="title" :iconWidh="iconWidth" />
    <div class="list">
      <ul>
        <li class="list-title">
          <div>时间</div>
          <div>无人机</div>
          <div>事件</div>
        </li>
        <div class="scroll" :style="{ width: `${width}px` }">
          <ul class="scroll-content" :id="`scroll_${no}`">
            <li
              class="list-li"
              v-for="(item, index) in event"
              :key="`sameDayLists_${index}`"
              ref="scrollItem"
            >
              <div>{{ item.time }}</div>
              <div>{{ item.plane }}</div>
              <div>{{ item.event }}</div>
            </li>
          </ul>
        </div>
      </ul>
    </div>
  </div>
</template>
<script>
import Title from './Title.vue';
export default {
  name: 'ListEvent',
  components: {
    Title,
  },
  props: {
    eventList: {
      type: Array,
    },
    title: {
      type: String,
      default: '当天事件',
    },
    icon: {
      type: String,
      default: 'dangtianIcon',
    },
    width: {
      type: String,
      default: '538',
    },
    iconWidth: {
      type: String,
      default: '33',
    },
    no: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {
      event: [],
    };
  },
  mounted() {
    this.event = this.eventList;
    this.startmarquee(67, 5000, `scroll_${this.no}`);
  },
  methods: {
    startmarquee(lh, speed, marqueeObj) {
      let topDistance = 0;
      var o = document.getElementById(marqueeObj);
      setInterval(() => {
        topDistance = topDistance + lh;
        o.style.top = -topDistance + 'px';
        o.style.transition = 'top 0.5s';
      }, speed);
    },
  },
};
</script>
<style lang="less">
.first-content {
  .list {
    margin-top: 12px;
    ul {
      .list-title {
        display: flex;
        justify-content: space-around;
        line-height: 52px;
        font-size: 22px;
        font-weight: 500;
        color: #abf8ff;
        div {
          text-align: center;
          &:first-child {
            width: 12%;
          }
          &:nth-child(2) {
            width: 14%;
          }
          &:nth-child(3) {
            width: 46%;
          }
        }
      }
      .scroll {
        position: relative;
        width: 100%;
        height: 333px;
        overflow: hidden;
      }
      .scroll-content {
        position: absolute;
        top: 0;
        padding: 0;
        width: 98%;
        // animation: 3s move infinite normal;
      }
      // .scroll:hover {
      //   .scroll-content {
      //     animation-play-state: paused;
      //     -webkit-animation-play-state: paused;
      //   }
      // }
      .list-li {
        margin-bottom: 11px;
        display: flex;
        justify-content: space-around;
        border: 1px transparent solid;
        border-image: linear-gradient(
            to right,
            rgb(25, 105, 127),
            rgba(255, 0, 0, 0),
            rgb(25, 105, 127)
          )
          1 10;
        line-height: 54px;
        background: #1a2e3d;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:first-child {
            width: 18%;
          }
          &:nth-child(2) {
            width: 14%;
          }
          &:nth-child(3) {
            width: 46%;
          }
        }
      }
    }
  }
}
@keyframes move {
  0% {
    top: 0;
  }
  100% {
    top: -65px;
  }
}
</style>
