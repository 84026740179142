<template>
  <div class="left">
    <div class="first">
      <ListEvent
        iconWidth="38"
        :eventList="sameDayLists"
        title="当天事件"
        no="1"
      />
      <ListEvent
        width="612"
        :eventList="sameDayLists"
        icon="zhongdianIcon"
        title="重点事件"
        no="2"
      />
      <ListEvent
        width="612"
        iconWidth="30"
        :eventList="sameDayLists"
        icon="lishiIcon"
        title="历史事件"
        no="3"
      />
    </div>
    <div class="second">
      <NumContent
        icon="aiIcon"
        title="AI识别"
        :list="aiLists"
        iconWidth="150"
        iconHeight="100"
      />
      <TaskContent :list="taskLists" />
    </div>
    <div class="third">
      <ChartsContent />
    </div>
  </div>
</template>
<script>
import ListEvent from './ListEvent.vue';
import NumContent from './NumContent.vue';
import TaskContent from './TaskContent.vue';
import ChartsContent from './ChartsContent.vue';
export default {
  components: {
    ListEvent,
    NumContent,
    ChartsContent,
    TaskContent,
  },
  data() {
    return {
      index: 0,
      sameDayLists: [
        {
          time: '07:33:12',
          plane: '城运1号',
          event: '在干将东路附近发现交通拥堵',
        },
        {
          time: '07:33:12',
          plane: '城运2号',
          event: '在观前街附近发现人群聚集',
        },
        {
          time: '07:33:12',
          plane: '城运3号',
          event: '在干将东路附近发现交通拥堵',
        },
        {
          time: '07:33:12',
          plane: '城运4号',
          event: '在干将东路附近发现交通拥堵',
        },
        {
          time: '07:33:12',
          plane: '城运5号',
          event: '在平江路附近发现人群聚集',
        },
        {
          time: '09:33:12',
          plane: '城运1号',
          event: '在干将东路附近发现交通拥堵',
        },
        {
          time: '09:33:12',
          plane: '城运2号',
          event: '在观前街附近发现人群聚集',
        },
        {
          time: '10:33:12',
          plane: '城运3号',
          event: '在干将东路附近发现交通拥堵',
        },
        {
          time: '11:33:12',
          plane: '城运4号',
          event: '在干将东路附近发现交通拥堵',
        },
        {
          time: '12:33:12',
          plane: '城运5号',
          event: '在平江路附近发现人群聚集',
        },
      ],
      aiLists: [
        {
          type: 'car',
          num: '121',
          tip: '实线变道',
          percent: '5.3%',
          isUp: false,
        },
        {
          type: 'moreCar',
          num: '32',
          tip: '道路拥堵',
          percent: '5.3%',
          isUp: false,
        },
        {
          type: 'truck',
          num: '152',
          tip: '货车上高架',
          percent: '1.2%',
          isUp: true,
        },
      ],
      taskLists: [
        {
          type: 'toBeExecuted',
          num: '152',
          tip: '待执行',
          percent: '5.3%',
          isUp: false,
        },
        {
          type: 'executing',
          num: '312',
          tip: '执行中',
          percent: '1.6%',
          isUp: true,
        },
        {
          type: 'complete',
          num: '21',
          tip: '已完成',
          percent: '42%',
          isUp: true,
        },
      ],
    };
  },
  mounted() {
    this.addItems();
  },
  destroyed() {
    clearInterval();
  },
  methods: {
    addItems() {
      setInterval(() => {
        this.sameDayLists.push(this.sameDayLists[this.index]);
        this.index++;
      }, 5000);
    },
  },
};
</script>
<style lang="less" scoped>
.left {
  width: 1911px;
  height: 1261px;
  background: rgba(27, 46, 61, 0.4);
  border: 1px solid #213a4d;
  display: flex;
  flex-direction: column;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 20px;
  // border: 1px solid red;
  .first {
    display: flex;
    justify-content: space-between;
  }
  .second {
    margin-top: 76px;
    display: flex;
    justify-content: space-between;
  }
  .third {
    margin-top: 76px;
    // border: 1px solid red;
    display: flex;
    justify-content: space-between;
  }
}
</style>
