var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"screen",style:({
      transformOrigin: 'center top',
      transform: ("scale(" + _vm.scalseNum + "," + _vm.scalseNum + ")"),
      '-webkit-transform': ("scale(" + _vm.scalseNum + "," + _vm.scalseNum + ")"),
      '-moz-transform': ("scale(" + _vm.scalseNum + "," + _vm.scalseNum + ")"),
      '-o-transform': ("scale(" + _vm.scalseNum + "," + _vm.scalseNum + ")"),
      '-ms-transform': ("scale(" + _vm.scalseNum + "," + _vm.scalseNum + ")"),
    })},[_c('left-content'),_c('middle-content'),_c('right-content')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }