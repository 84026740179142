<template>
  <div
    :id="option.id"
    :style="{ width: width, height: height }"
    class="chartContent"
  ></div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  name: 'Charts',
  props: {
    option: {
      type: Object,
    },
    width: {
      type: String,
      default: '500px',
    },
    height: {
      type: String,
      default: '400px',
    },
  },
  data() {
    return {
      msg: '111',
      myChart: {},
      chartData: [36, 24, 20, 28, 30, 24, 45],
      chart1: {
        backgroundColor: 'transparent',
        xAxis: {
          boundaryGap: false,
          type: 'category',
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
          axisTick: false, //隐藏刻度线
          splitLine: {
            show: true, //是否显示表格后横线,
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.36)',
              opacity: 0.2,
            },
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.36)',
              opacity: 0.2,
            },
          },
          axisLabel: {
            color: '#fff', //坐标的字体颜色
            fontSize: '16px',
            margin: 39,
          },
        },
        yAxis: {
          name: '数量(次)',
          nameGap: '20',
          nameTextStyle: {
            color: '#fff',
            fontSize: '16px',
          },
          boundaryGap: [0, '30%'],
          type: 'value',
          offset: '20',
          splitLine: {
            show: true, //是否显示表格后横线
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.36)',
              opacity: 0.2,
            },
          },
          axisLabel: {
            color: '#fff', //坐标的字体颜色
            fontSize: '16px',
          },
        },
        series: [
          {
            data: [36, 24, 20, 28, 30, 24, 45],
            type: 'line',
            smooth: 0.7,
            symbol: 'circle',
            symbolSize: '10',
            animationDuration: 3000,
            animationEasing: 'linear',
            lineStyle: {
              width: 0,
            },
            itemStyle: {
              color: '#FFBF51',
            },
            markLine: {
              symbol: ['none', 'none'],
              label: { show: true },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FFBF51',
                },
                {
                  offset: 0.1,
                  color: 'rgba(255, 191, 81, 1)',
                },
                {
                  offset: 0.2,
                  color: 'rgba(255, 191, 81, 0.9)',
                },
                {
                  offset: 0.5,
                  color: 'rgba(255, 191, 81, 0.5)',
                },
                {
                  offset: 0.8,
                  color: 'rgba(255, 191, 81, 0.2)',
                },
                {
                  offset: 1,
                  color: 'rgba(255, 0, 0, 0)',
                },
              ]),
            },
          },
        ],
      },
      chart2: {
        backgroundColor: 'transparent',
        xAxis: {
          boundaryGap: false,
          type: 'category',
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
          axisTick: false, //隐藏刻度线
          splitLine: {
            show: true, //是否显示表格后横线,
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.36)',
              opacity: 0.2,
            },
          },
          axisLine: {
            // onZero: true,s
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.36)',
              opacity: 0.2,
            },
          },
          axisLabel: {
            color: '#fff', //坐标的字体颜色
            fontSize: '16px',
            margin: 39,
          },
        },
        yAxis: {
          name: '时长(时)',
          nameGap: '20',
          nameTextStyle: {
            color: '#fff',
            fontSize: '16px',
          },
          boundaryGap: [0, '30%'],
          type: 'value',
          offset: '20',
          splitLine: {
            show: true, //是否显示表格后横线
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.36)',
              opacity: 0.2,
            },
          },
          axisLabel: {
            color: '#fff', //坐标的字体颜色
            fontSize: '16px',
          },
        },
        series: [
          {
            tooltip: {
              show: false,
            },
            data: [36, 24, 20, 28, 30, 24, 45],
            type: 'bar',
            animationDuration: 3000,
            animationEasing: 'linear',
            barWidth: 13,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                0,
                1,
                0,
                0,
                [
                  {
                    offset: 0,
                    color: 'rgb(46, 99, 166)', // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: 'rgb(45, 118, 173)', // 50% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgb(45, 132, 174)', // 100% 处的颜色
                  },
                ],
                false
              ),
            },
            // barGap: 0
          },
          {
            type: 'bar',
            barWidth: 12,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                0,
                1,
                0,
                0,
                [
                  {
                    offset: 0,
                    color: 'rgb(62, 155, 254)', // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: 'rgb(62, 185, 252)', // 50% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgb(45, 146, 179)', // 100% 处的颜色
                  },
                ],
                false
              ),
            },
            animationDuration: 3000,
            animationEasing: 'linear',
            barGap: 0,
            data: [36, 24, 20, 28, 30, 24, 45],
          },
          {
            tooltip: {
              show: false,
            },
            type: 'pictorialBar',
            itemStyle: {
              color: 'rgba(53, 221, 254)',
            },
            symbol: 'path://M 0,0 l 120,0 l -30,60 l -120,0 z',
            symbolSize: ['20', '15'],
            symbolOffset: ['0', '-7'], // 左右 ，上下
            symbolRotate: -35,
            symbolPosition: 'end',
            data: [36, 24, 20, 28, 30, 24, 45],
            z: 3,
          },
        ],
      },
      chart3: {
        backgroundColor: 'transparent',
        xAxis: {
          boundaryGap: false,
          type: 'category',
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
          axisTick: false, //隐藏刻度线
          splitLine: {
            show: true, //是否显示表格后横线,
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.36)',
              opacity: 0.2,
            },
          },
          axisLine: {
            // onZero: true,s
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.36)',
              opacity: 0.2,
            },
          },
          axisLabel: {
            color: '#fff', //坐标的字体颜色
            fontSize: '16px',
            margin: 39,
          },
        },
        yAxis: {
          name: '距离(米)',
          nameGap: '20',
          nameTextStyle: {
            color: '#fff',
            fontSize: '16px',
          },
          boundaryGap: [0, '30%'],
          type: 'value',
          offset: '20',
          splitLine: {
            show: true, //是否显示表格后横线
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.36)',
              opacity: 0.2,
            },
          },
          axisLabel: {
            color: '#fff', //坐标的字体颜色
            fontSize: '16px',
          },
        },
        series: [
          {
            data: [36, 24, 20, 28, 30, 24, 45],
            type: 'line',
            // smooth: 0.8,
            symbol: 'circle',
            symbolSize: '10',
            animationDuration: 3000,
            animationEasing: 'linear',
            lineStyle: {
              width: 0,
            },
            itemStyle: {
              color: '#99FBCC',
            },
            markLine: {
              symbol: ['none', 'none'],
              label: { show: true },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#99FBCC',
                },
                {
                  offset: 0.1,
                  color: '#99FBCC',
                },
                {
                  offset: 0.2,
                  color: 'rgba(153, 251, 204, 1)',
                },
                {
                  offset: 0.3,
                  color: 'rgba(153, 251, 204, 0.8)',
                },
                {
                  offset: 0.5,
                  color: 'rgba(153, 251, 204, 0.4)',
                },
                {
                  offset: 0.8,
                  color: 'rgba(153, 251, 204, 0.3)',
                },
                {
                  offset: 0.9,
                  color: 'rgba(153, 251, 204, 0.1)',
                },
                {
                  offset: 1,
                  color: 'rgba(255, 0, 0, 0)',
                },
              ]),
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    drawLine() {
      let tempOption = this.option;
      let chartDom = document.getElementById(tempOption.id);
      if (chartDom) {
        this.myChart = echarts.init(chartDom);
        this.randomData();
        if (tempOption.type === 'chart1') {
          this.myChart.setOption(this.chart1);
        } else if (tempOption.type === 'chart2') {
          this.myChart.setOption(this.chart2);
        } else {
          this.myChart.setOption(this.chart3);
        }
        this.randomData();
      }
    },
    randomData() {
      setInterval(() => {
        let value = 0;
        let temp1 = this.chartData.map((item) => {
          // value = Math.ceil(Math.random() * 10);
          value = parseInt(Math.random() * (10 + 1), 10);
          return (item = item + value);
        });
        let temp2 = this.chartData.map((item) => {
          value = parseInt(Math.random() * (10 + 1), 10);
          return (item = item + value);
        });
        let temp3 = this.chartData.map((item) => {
          value = parseInt(Math.random() * (10 + 1), 10);
          return (item = item + value);
        });
        if (this.option.type === 'chart1') {
          this.myChart.setOption({
            series: [
              {
                animationDurationUpdate: 500,
                data: temp1,
              },
            ],
          });
        } else if (this.option.type === 'chart2') {
          this.myChart.setOption({
            series: [
              {
                data: temp2,
              },
              {
                data: temp2,
              },
              {
                data: temp2,
              },
            ],
          });
        } else {
          this.myChart.setOption({
            series: [
              {
                animationDurationUpdate: 500,
                data: temp3,
              },
            ],
          });
        }
      }, 4000);
    },
  },
};
</script>
<style lang="less" scoped></style>
