<template>
  <div class="fullscreen-play">
    <!-- 名称 -->
    <div class="video-name">
      {{ videoObj.name }}
    </div>
    <!-- 关闭按钮 -->
    <div class="close" @click="close()"></div>
    <!-- 视频本身 -->
    <video :src="videoObj.path" autoplay muted loop></video>
  </div>
</template>
<script>
import Bus from '@/bus.js';
export default {
  name: 'FullscreenPlay',
  props: ['videoObj'],
  methods: {
    close: function () {
      // 传空对象，关闭视频
      Bus.$emit('getSource', {});
    },
  },
};
</script>
<style lang="less" scoped>
.fullscreen-play {
  width: 1966px;
  height: 1200px;
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 20px;
  overflow: hidden;
  .video-name {
    width: 100px;
    height: 32px;
    background: #0d1924;
    opacity: 0.7;
    border-radius: 2px;
    text-align: center;
    line-height: 32px;
    color: #fff;
    position: absolute;
    top: 16px;
    left: 10px;
  }
  .close {
    width: 32px;
    height: 32px;
    background: url('../../assets/images/quit.png');
    background-size: 100% 100%;
    position: absolute;
    top: 16px;
    right: 10px;
    // 层级关系，否则点击事件不生效
    z-index: 101;
  }
  video {
    width: 1966px;
    height: 1200px;
    object-fit: fill;
  }
}
</style>
