<template>
  <div class="plane">
    <div
      class="green-plane"
      :class="{ yellowPlane: isActive }"
      :style="{ left: `${left}px`, top: `${top}px` }"
      v-if="type === '1'"
    >
      <div class="tip">
        <img
          src="../../assets/images/middle/green-bg-plane.png"
          alt="green-bg-plane"
        />
        <ul>
          <li>无人机： {{ text1 }}</li>
          <li>自动任务：{{ text2 }}</li>
        </ul>
      </div>
      <div class="green-bg">
        <img
          src="../../assets/images/middle/green-plane2.png"
          alt="green-plane-icon"
        />
      </div>
      <b></b>
      <b></b>
    </div>
    <div
      class="grey-plane"
      :style="{ left: `${left}px`, top: `${top}px` }"
      v-if="type === '2'"
    >
      <div class="tip">
        <span class="text">正在建设中……</span>
      </div>
      <img
        src="../../assets/images/middle/grey-plane.png"
        alt="green-plane-icon"
      />
    </div>
    <div
      class="plane-parking-plate"
      :class="{ yellowParkPlate: isActive }"
      :style="{ left: `${left}px`, top: `${top}px` }"
      v-if="type === '3'"
    >
      <div class="tip">
        <img
          src="../../assets/images/middle/blue-bg-icon.png"
          alt="blue-bg-icon-plane"
        />
        <ul>
          <li>停机坪：{{ text1 }}</li>
          <li>无人机：{{ text2 }}</li>
        </ul>
      </div>
      <img
        src="../../assets/images/middle/plane-parking.png"
        alt="plane-parking"
      />
      <b></b>
      <b></b>
    </div>
    <div
      class="red-warning"
      :style="{ left: `${left}px`, top: `${top}px` }"
      v-if="type === '4'"
    >
      <div class="red-bg">
        <img
          src="../../assets/images/middle/red-warn-icon.png"
          alt="plane-parking"
        />
      </div>
      <div class="tip">
        <img
          src="../../assets/images/middle/yujing-icon.png"
          alt="yujing-icon"
        />
        <ul>
          <li>{{ text1 }}</li>
          <li>{{ text2 }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'plane',
  props: {
    type: {
      type: String,
      default: '1',
    },
    left: {
      type: String,
      default: '0',
    },
    top: {
      type: String,
      default: '0',
    },
    text1: {
      type: String,
      default: '',
    },
    text2: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="less" scoped>
.plane {
  .green-plane {
    position: relative;
    width: 111px;
    height: 78px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 3;
    .green-bg {
      width: 100%;
      height: 100%;
      background-image: url('../../assets/images/middle/green-small-circle.png');
      background-size: 100% 100%;
      z-index: 3;
    }
    img {
      position: absolute;
      left: 19.5px;
      top: -15px;
      width: 70px;
      height: 57px;
    }
    .tip {
      position: absolute;
      top: -96.1px;
      left: -116px;
      width: 355px;
      height: 76.1px;
      background-image: url('../../assets/images/middle/green-bg.png');
      background-size: 100% 100%;
      img {
        position: relative;
        left: 24px;
        top: 20px;
        width: 32px;
        height: 32px;
      }
      ul {
        position: relative;
        left: 90px;
        top: -27px;
        font-size: 20px;
        color: #fff;
      }
    }
  }
  .green-plane b {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: block;
    position: absolute;
    left: -1.5px;
    top: -1.5px;
    z-index: 1;
    border: 1px solid #97f4b8;
    background-color: rgba(151, 244, 184, 0.6);
    background-image: url('../../assets/images/middle/green-small-circle.png');
    background-size: 100% 100%;
    opacity: 0.5;
  }
  .green-plane b:nth-of-type(1) {
    -webkit-animation: greenone 1.5s linear infinite;
    -moz-animation: greenone 1.5s linear infinite;
    -ms-animation: greenone 1.5s linear infinite;
    -o-animation: greenone 1.5s linear infinite;
    animation: greenone 1.5s linear infinite;
    z-index: 1;
  }

  .green-plane b:nth-of-type(2) {
    -webkit-animation: greentwo 2s linear infinite;
    -moz-animation: greentwo 1.5s linear infinite;
    -ms-animation: greentwo 1.5s linear infinite;
    -o-animation: greentwo 1.5s linear infinite;
    animation: greentwo 2s linear infinite;
    opacity: 0;
    z-index: 1;
  }

  .yellowPlane {
    .green-bg {
      background-image: url('../../assets/images/middle/yellow-small-circle.png');
    }
    .tip {
      background-image: url('../../assets/images/middle/yellow-bg.png');
    }
    b {
      border: 1px solid rgba(239, 231, 10);
      background-color: rgba(239, 231, 10, 0.6);
      background-image: url('../../assets/images/middle/yellow-small-circle.png');
    }
    b:nth-of-type(1) {
      background-color: rgba(239, 231, 10, 0.6);
    }
  }

  //  灰色
  .grey-plane {
    position: relative;
    width: 306px;
    height: 190px;
    cursor: pointer;
    border: 1px solid rgba(195, 195, 195, 1);
    border-radius: 50%;
    background-image: url('../../assets/images/middle/grey-big-circle.png');
    background-size: 100% 100%;
    z-index: 2;
    img {
      position: absolute;
      left: 117px;
      top: 26px;
      width: 70px;
      height: 57px;
    }
    .tip {
      position: absolute;
      top: -55.1px;
      left: 10px;
      width: 271px;
      height: 76.1px;
      line-height: 76px;
      background-image: url('../../assets/images/middle/jianshe-bg.png');
      background-size: 100% 100%;
      .text {
        position: relative;
        font-size: 20px;
        left: 87px;
      }
    }
  }
  // 停机坪
  .plane-parking-plate {
    position: relative;
    width: 87.4px;
    height: 57.3px;
    cursor: pointer;
    border-radius: 50%;
    background-image: url('../../assets/images/middle/grey-small-circle.png');
    background-size: 100% 100%;
    z-index: 3;
    img {
      position: absolute;
      left: 10px;
      top: -28px;
      width: 68px;
    }
    .tip {
      position: absolute;
      top: -112.1px;
      left: -130px;
      width: 355px;
      height: 76.1px;
      background-image: url('../../assets/images/middle/blue-bg.png');
      background-size: 100% 100%;
      img {
        position: relative;
        left: 24px;
        top: 20px;
        width: 32px;
        height: 32px;
      }
      ul {
        position: relative;
        left: 90px;
        top: -27px;
        font-size: 20px;
        color: #fff;
      }
    }
  }
  .plane-parking-plate b {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background-image: url('../../assets/images/middle/grey-small-circle.png');
    background-size: 100% 100%;
  }
  .plane-parking-plate b:nth-of-type(1) {
    -webkit-animation: greyone 1s linear infinite;
    -moz-animation: greyone 1s linear infinite;
    -ms-animation: greyone 1s linear infinite;
    -o-animation: greyone 1s linear infinite;
    animation: greyone 1s linear infinite;
    z-index: 1;
  }
  .plane-parking-plate b:nth-of-type(2) {
    -webkit-animation: greytwo 1s linear infinite;
    -moz-animation: greytwo 1s linear infinite;
    -ms-animation: greytwo 1s linear infinite;
    -o-animation: greytwo 1s linear infinite;
    animation: greytwo 1s linear infinite;
    opacity: 0;
    z-index: 1;
  }
  .yellowParkPlate {
    background-image: url('../../assets/images/middle/yellow-small-circle.png');
    .tip {
      background-image: url('../../assets/images/middle/yellow-bg.png');
    }
    b {
      background-image: url('../../assets/images/middle/yellow-small-circle.png');
    }
  }

  // 红色预警
  .red-warning {
    position: relative;
    width: 87.4px;
    height: 57.3px;
    cursor: pointer;
    z-index: 3;
    .red-bg {
      width: 100%;
      height: 100%;
      background-image: url('../../assets/images/middle/red-circle.png');
      background-size: 100% 100%;
      opacity: 1;
      -webkit-animation: redone 2s linear infinite alternate;
      -moz-animation: redone 2s linear infinite alternate;
      -ms-animation: redone 2s linear infinite alternate;
      -o-animation: redone 2s linear infinite alternate;
      animation: redone 2s linear infinite alternate;
      img {
        position: absolute;
        left: 16.8px;
        top: -51px;
        width: 56px;
      }
    }
    .tip {
      position: absolute;
      top: -112.1px;
      left: -130px;
      width: 355px;
      height: 76.1px;
      background-image: url('../../assets/images/middle/red-bg.png');
      background-size: 100% 100%;
      img {
        position: relative;
        left: 24px;
        top: 20px;
        width: 32px;
        height: 32px;
      }
      ul {
        position: relative;
        left: 87px;
        top: -30px;
        font-size: 20px;
        color: #fff;
      }
    }
  }
}
// greenone
@-webkit-keyframes greenone {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }
}

@-moz-keyframes greenone {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }
}

@-ms-keyframes greenone {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }
}

@-o-transforms greenone {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }
}

@keyframes greenone {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: rgba(151, 244, 184, 0.6);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }
}

// greentwo
@-webkit-keyframes greentwo {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(4);
    transform: scale(4);
  }
}

@-moz-keyframes greentwo {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(4);
    transform: scale(4);
  }
}

@-ms-keyframes greentwo {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(4);
    transform: scale(4);
  }
}

@-o-transforms greentwo {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(4);
    transform: scale(4);
  }
}

@keyframes greentwo {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(4);
    transform: scale(4);
  }
}


// greyone
@-webkit-keyframes greyone {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}

@-moz-keyframes greyone {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}
@-ms-keyframes greyone {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}

@-o-transforms greyone {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}

@keyframes greyone {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}

// greytwo
@-webkit-keyframes greytwo {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }
}

@-moz-keyframes greytwo {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }
}

@-ms-keyframes greytwo {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }
}

@-o-transforms greytwo {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }
}

@keyframes greytwo {
  0% {
    opacity: 0.6;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }
}

// redone
@-webkit-keyframes redone {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes redone {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes redone {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-transforms redone {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes redone {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
