import { render, staticRenderFns } from "./LpTitle.vue?vue&type=template&id=c4a88940&scoped=true&"
import script from "./LpTitle.vue?vue&type=script&lang=js&"
export * from "./LpTitle.vue?vue&type=script&lang=js&"
import style0 from "./LpTitle.vue?vue&type=style&index=0&id=c4a88940&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4a88940",
  null
  
)

export default component.exports