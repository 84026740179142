<template>
  <div class="lp-switch">
    <div class="labelBox">
      <input
        type="checkbox"
        class="checkbox-input switch-component"
        v-model="value"
        @change="handleClick"
        :disabled="disabled"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'LpSwitch',
  //是否勾选，是否禁用,当前dom的id
  props: ['checked', 'disabled', 'id'],
  data() {
    return {
      value: this.checked,
      target: this.id,
    };
  },
  methods: {
    //抛出布尔值
    handleClick() {
      this.$emit('handleClick', this.value, this.target);
    },
  },
};
</script>
<style lang="less" scoped>
.lp-switch {
  position: relative;
  width: 60px;
  height: 30px;
  background-color: rgba(214, 225, 255, 1);
  box-shadow: 0px 2px 5px 0px rgba(18, 32, 46, 0.6);
  border-radius: 30px;
  border: none;
  outline: none;
  -webkit-appearance: none;
  transition: all 0.2s ease;

  /* 背景层 */
  .switch-component {
    position: relative;
    width: 60px;
    height: 30px;
    background-color: rgba(214, 225, 255, 1);
    box-shadow: 0px 2px 5px 0px rgba(18, 32, 46, 0.6);
    border-radius: 30px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    transition: all 0.2s ease;
  }

  /* 按钮 */
  .switch-component::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: #eee;
    border: 1px solid #aaa;
    border-radius: 50%;
    transition: all 0.2s ease;
  }

  /* 选中状态时，背景色切换 */
  .switch-component:checked {
    background: rgba(13, 25, 36, 0.6);
    box-shadow: 0px 2px 5px 0px rgba(18, 32, 46, 0.6);
  }

  /* 选中状态时，按钮的位置移动 */
  .switch-component:checked::after {
    left: 50%;
    background-color: #72f5af;
  }

  /*禁用状态*/
  .switch-component:disabled {
    background-color: #0b131b;
  }

  // vue项目中，使用input type=checkbox需要加这一段
  // .checkbox-input {
  //   background-color: initial;
  //   cursor: default;
  //   appearance: checkbox;
  //   box-sizing: border-box;
  //   padding: initial;
  //   border: initial;
  // }
}
</style>
