<template>
  <div class="num-content" :style="{ width: `${width}px` }">
    <Title :title="title" :icon="icon" :iconWidth="titleIconWidth" />
    <div class="content">
      <div class="first" v-for="(item, index) in list" :key="`list_${index}`">
        <img
          :src="require(`../../assets/icons/${item.type}.png`)"
          :alt="item.type"
          :style="{ width: `${iconWidth}px`, height: `${iconHeight}px` }"
        />
        <div class="list">
          <div class="tip tip-background-bg">
            <span
              :style="{
                marginLeft: item.tip && item.tip.length > 4 ? '15px' : '',
              }"
              >{{ item.tip }}</span
            >
          </div>
          <div class="count" :id="`count_${index}`">
            {{ item.num }}
          </div>
          <div class="compare">
            <span>较昨日</span>
            <span
              class="num"
              :style="{ color: item.isUp ? '#72F6B2' : '#FF5243' }"
              >{{ item.percent }}</span
            >
            <span
              class="compare-icon"
              :class="{ down: item.isUp, up: !item.isUp }"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Title from './Title.vue';
export default {
  name: 'NumContent',
  components: {
    Title,
  },
  props: {
    title: {
      type: String,
      default: 'AI识别',
    },
    icon: {
      type: String,
      default: 'aiIcon',
    },
    width: {
      type: String,
      default: '865',
    },
    iconWidth: {
      type: String,
      default: '',
    },
    iconHeight: {
      type: String,
      default: '',
    },
    titleIconWidth: {
      type: String,
      default: '33',
    },
    list: {
      type: Array,
    },
  },
  mounted() {
    this.numDynamic('count_0', 10, this.list[0].num);
    this.numDynamic('count_1', 20, this.list[1].num);
    this.numDynamic('count_2', 9, this.list[2].num);
    this.numAdd('count_0', 4000, 1, this.list[0].num);
    this.numAdd('count_1', 6000, 1, this.list[1].num);
    this.numAdd('count_2', 5000, 1, this.list[2].num);
  },
  destroyed() {
    clearInterval();
  },
  methods: {
    numDynamic(id, speed, value) {
      let span1 = document.getElementById(id);
      if (value > 0) {
        let num = 0;
        let t = setInterval(function () {
          num++;
          span1.innerText = num;
          if (num == value) {
            clearInterval(t);
          }
        }, speed);
      } else {
        span1.innerText = value;
      }
    },
    numAdd(id, speed, add, value) {
      let span1 = document.getElementById(id);
      let num = Number(value);
      setInterval(function () {
        num = Number(num + add);
        span1.innerText = num;
      }, speed);
    },
  },
};
</script>
<style lang="less" scoped>
.num-content {
  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 37px;
    .first {
      display: flex;
      .list {
        margin-left: -15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      .tip {
        width: 130px;
        height: 33px;
        font-size: 16px;
        line-height: 32px;
        text-align: center;
        font-weight: 400;
        color: #ffffff;
      }
      .tip-background-bg {
        background-size: 100% 100%;
        background-image: url('../../assets/icons/aiBg.png');
      }
      .count {
        min-width: 75px;
        text-align: center;
        font-size: 44px;
        font-family: 'impact';
        font-weight: 400;
        color: #ffffff;
        transition: 0.3s ease;
      }
      .count2 {
        text-align: center;
        background: linear-gradient(
          0deg,
          #5fcafa,
          #162734,
          rgba(255, 0, 0, 0),
          rgba(255, 0, 0, 0)
        );
      }
      .compare {
        font-size: 17px;
        font-weight: 400;
        color: #ffffff;
        line-height: 19px;
        .num {
          margin-left: 11px;
        }
      }
      .compare-icon {
        margin-left: 3px;
        display: inline-block;
        width: 14px;
        height: 19px;
      }
      .up {
        background-size: 100% 100%;
        background-image: url('../../assets/icons/upIcon.png');
      }
      .down {
        background-size: 100% 100%;
        background-image: url('../../assets/icons/downIcon.png');
      }
    }
  }
}
</style>
