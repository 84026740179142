<template>
  <div class="charts-content">
    <Title title="飞行统计" icon="statisticIcon" iconWidth="43" />
    <div class="charts">
      <div class="first">
        <div class="title">
          <img src="../../assets/icons/chart1Icon.png" alt="chart1Icon" />
        </div>
        <div class="chart-border">
          <Charts :option="charts1" />
        </div>
      </div>
      <div class="first">
        <div class="title">
          <img src="../../assets/icons/chart2Icon.png" alt="chart2Icon" />
        </div>
        <div class="chart-border">
          <Charts :option="charts2" />
        </div>
      </div>
      <div class="first">
        <div class="title">
          <img src="../../assets/icons/chart3Icon.png" alt="chart3Icon" />
        </div>
        <div class="chart-border">
          <Charts :option="charts3" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Title from './Title.vue';
import Charts from './Charts.vue';
export default {
  name: 'ChartsContent',
  components: {
    Title,
    Charts,
  },
  data() {
    return {
      charts1: {
        id: 'chart1',
        type: 'chart1',
      },
      charts2: {
        id: 'chart2',
        type: 'chart2',
      },
      charts3: {
        id: 'chart3',
        type: 'chart3',
      },
    };
  },
};
</script>
<style lang="less">
.charts-content {
  width: 1836px;
  .charts {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .first {
      position: relative;
    }
    .chart-border {
      background-image: url('../../assets/icons/chartBg.png');
      background-repeat: no-repeat;
      background-size: 95% 20%;
      background-position: center 88%;
    }
    .title {
      position: absolute;
      left: 35%;
      img {
        width: 139px;
        height: 39px;
      }
    }
  }
}
</style>
