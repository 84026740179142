<template>
  <div class="list-of-video">
    <lp-title :icon="listIcon" :title="listName" style="margin-left: 14px" />
    <div class="video-items">
      <video-item
        v-for="(item, index) in data"
        :key="index"
        :videoData="item"
      />
    </div>
  </div>
</template>
<script>
import LpTitle from '@/components/public/LpTitle.vue';
import VideoItem from './VideoItem.vue';
export default {
  name: 'list-of-video',
  components: {
    LpTitle,
    VideoItem,
  },
  props: ['listName', 'listIcon', 'data'],
};
</script>

<style lang="less" scoped>
.list-of-video {
  .video-items {
    display: flex;
    margin: 21px 0 29px 0;
  }
}
</style>
