<template>
  <div id="app">
    <div
      class="screen"
      :style="{
        transformOrigin: 'center top',
        transform: `scale(${scalseNum},${scalseNum})`,
        '-webkit-transform': `scale(${scalseNum},${scalseNum})`,
        '-moz-transform': `scale(${scalseNum},${scalseNum})`,
        '-o-transform': `scale(${scalseNum},${scalseNum})`,
        '-ms-transform': `scale(${scalseNum},${scalseNum})`,
      }"
    >
      <left-content />
      <middle-content />
      <right-content />
    </div>
  </div>
</template>

<script>
import LeftContent from '@/components/left-content/Main.vue';
import MiddleContent from '@/components/middle-content/Main.vue';
import RightContent from '@/components/right-content/Main.vue';

export default {
  name: 'App',
  data() {
    return {
      scalseNum: 1,
    };
  },
  mounted() {
    this.getScale();
    window.addEventListener('resize', () => {
      this.getScale();
    });
  },
  components: {
    LeftContent,
    MiddleContent,
    RightContent,
  },
  methods: {
    getScale() {
      // 固定好宽高比，计算出最合适的缩放比
      const width = 7680;
      const height = 1440;
      let ww = Number(window.innerWidth / width);
      let wh = Number(window.innerHeight / height);
      this.scalseNum = ww < wh ? ww : wh;
      // return ww < wh ? ww : wh;
      // let w_height = Number(document.documentElement.clientHeight / 1080);
      // this.scalseNum = w_height;
      // console.log(this.scalseNum);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getScale());
  },
};
</script>

<style lang="less">
@import url('./assets/css/common.less');
#app {
  width: 100%;
  height: 100%;
  background-color: rgb(8, 17, 33, 0.5);
  font-family: PingFangSC-Medium, PingFang SC, Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  .screen {
    width: 7680px;
    height: 1440px;
    background-image: url('./assets/images/uav-bg.jpg');
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform-origin: center top 0px;
    position: relative;
    left: 50%;
    margin-left: -3840px;
    // margin: 0 auto;
  }
}
@font-face {
  font-family: 'impact';
  src: url('./assets/font/Impact.ttf');
}
</style>
