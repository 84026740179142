// 无人机列表
// status代表的状态 0：离线 1：在线 2：维护中
//  live代表的状态 0：关闭 1：打开
const urvList = [
  {
    uav: {
      id: 101,
      name: '城运1号',
      model: 'M300 RTK',
      status: 1,
      live: 1,
    },
    garage: {
      id: 201,
      name: '观前街机库',
      model: 'Compass-1',
      status: 1,
      live: 1,
    },
  },
  {
    uav: {
      id: 102,
      name: '城运2号',
      model: 'M300 RTK',
      status: 1,
      live: 1,
    },
    garage: {
      id: 202,
      name: '凤凰街机库',
      model: 'Compass-1',
      status: 1,
      live: 1,
    },
  },
  {
    uav: {
      id: 103,
      name: '城运3号',
      model: 'M300 RTK',
      status: 1,
      live: 1,
    },
    garage: {
      id: 203,
      name: '石路机库',
      model: 'Compass-1',
      status: 1,
      live: 1,
    },
  },
  {
    uav: {
      name: '城运4号',
      model: 'M300 RTK',
      status: 0,
      lvie: 0,
    },
    garage: {
      name: '劳动路机库',
      model: 'Compass-1',
      status: 0,
      live: 0,
    },
  },
  {
    uav: {
      name: '城运5号',
      model: 'M300 RTK',
      status: 2,
      live: 0,
    },
    garage: {
      name: '平江路机库',
      model: 'Compass-1',
      status: 2,
      live: 0,
    },
  },
  {
    uav: {
      name: '城运6号',
      model: 'M300 RTK',
      status: 0,

      live: 0,
    },
    garage: {
      name: '南门机库',
      model: 'Compass-1',
      status: 0,
      live: 0,
    },
  },
];

// 视频列表
const videoList = [
  {
    id: 101,
    name: '城运1号',
    path: 'uav_no1.mp4',
    type: 'uav',
    info: {
      model: 'Matrice 300 RTK',
      status: '任务中',
      height: '34',
      speed: '10',
      battery: [98, 95],
    },
  },
  {
    id: 102,
    name: '城运2号',
    path: 'uav_no2.mp4',
    type: 'uav',
    info: {
      model: 'Matrice 300 RTK',
      status: '任务中',
      height: '34',
      speed: '10',
      battery: [22, 22],
    },
  },
  {
    id: 103,
    name: '城运3号',
    path: 'uav_no3.mp4',
    type: 'uav',
    info: {
      model: 'Matrice 300 RTK',
      status: '任务中',
      height: '34',
      speed: '10',
      battery: [45, 45],
    },
  },
  {
    id: 501,
    name: '城运1号',
    path: 'uav_no4.mp4',
    type: 'uav',
    info: {
      model: 'Matrice 300 RTK',
      status: '任务中',
      height: '34',
      speed: '10',
      battery: [98, 95],
    },
  },
  {
    id: 502,
    name: '城运2号',
    path: 'uav_no5.mp4',
    type: 'uav',
    info: {
      model: 'Matrice 300 RTK',
      status: '任务中',
      height: '34',
      speed: '10',
      battery: [22, 22],
    },
  },
  {
    id: 503,
    name: '城运3号',
    path: 'uav_no6.mp4',
    type: 'uav',
    info: {
      model: 'Matrice 300 RTK',
      status: '任务中',
      height: '34',
      speed: '10',
      battery: [45, 45],
    },
  },
  {
    id: 201,
    name: '观前街机库',
    path: 'garage_no1.mp4',
    type: 'garage',
    info: {
      model: 'Compass-1',
      status: '任务中',
      tempOutside: '24',
      tempInside: '26',
      wind: ['东南风', '4'],
    },
  },
  {
    id: 202,
    name: '凤凰街机库',
    path: 'garage_no2.mp4',
    type: 'garage',
    info: {
      model: 'Compass-1',
      status: '任务中',
      tempOutside: '24',
      tempInside: '26',
      wind: ['东南风', '4'],
    },
  },
  {
    id: 203,
    name: '石路机库',
    path: 'garage_no3.mp4',
    type: 'garage',
    info: {
      model: 'Compass-1',
      status: '任务中',
      tempOutside: '24',
      tempInside: '26',
      wind: ['东南风', '4'],
    },
  },
];

export { urvList, videoList };
