<template>
  <div class="num-content" :style="{ width: `${width}px` }">
    <Title title="任务执行" icon="renwuIcon" iconWidth="29" />
    <div class="content">
      <div class="first">
        <img
          src="../../assets/icons/toBeExecuted.png"
          alt="toBeExecuted"
          style="width: 110px; height: 114px"
        />
        <div class="list">
          <div class="tip">待执行</div>
          <div class="count count2" id="toBeExecuted">{{ toBeExecuted }}</div>
          <div class="compare">
            <span>较昨日</span>
            <span class="num" style="color: #ff5243">5.3%</span>
            <span class="compare-icon up"></span>
          </div>
        </div>
      </div>
      <div class="border">
        <div class="block">&nbsp;</div>
      </div>
      <div class="first">
        <img
          src="../../assets/icons/executing.png"
          alt="executing"
          style="width: 110px; height: 116px"
        />
        <div class="list">
          <div class="tip">执行中</div>
          <div class="count count2" id="executing">{{ executing }}</div>
          <div class="compare">
            <span>较昨日</span>
            <span class="num" style="color: #ff5243">1.6%</span>
            <span class="compare-icon up"></span>
          </div>
        </div>
      </div>
      <div class="border">
        <div class="block">&nbsp;</div>
      </div>
      <div class="first">
        <img
          src="../../assets/icons/complete.png"
          alt="complete"
          style="width: 110px; height: 116px"
        />
        <div class="list">
          <div class="tip">已完成</div>
          <div class="count count2" id="complete">{{ complete }}</div>
          <div class="compare">
            <span>较昨日</span>
            <span class="num" style="color: #72f6b2">42%</span>
            <span class="compare-icon down"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Title from './Title.vue';
export default {
  name: 'NumContent',
  components: {
    Title,
  },
  props: {
    width: {
      type: String,
      default: '865',
    },
    list: {
      type: Array,
    },
  },
  data() {
    return {
      toBeExecuted: 152,
      executing: 212,
      complete: 21,
    };
  },
  mounted() {
    this.numDynamic('toBeExecuted', 10, this.toBeExecuted);
    this.numDynamic('executing', 6, this.executing);
    this.numDynamic('complete', 20, this.complete);
    this.numAdd('toBeExecuted', 4000, 1, this.toBeExecuted);
    this.numAdd('executing', 5000, 1, this.executing);
    this.numAdd('complete', 6000, 1, this.complete);
  },
  destroyed() {
    clearInterval();
  },
  methods: {
    numDynamic(id, speed, value) {
      let span1 = document.getElementById(id);
      if (value > 0) {
        let num = 0;
        let t = setInterval(function () {
          num++;
          span1.innerText = num;
          if (num == value) {
            clearInterval(t);
          }
        }, speed);
      } else {
        span1.innerText = value;
      }
    },
    numAdd(id, speed, add, value) {
      let span1 = document.getElementById(id);
      let num = Number(value);
      setInterval(function () {
        num = Number(num + add);
        span1.innerText = num;
      }, speed);
    },
  },
};
</script>
<style lang="less" scoped>
.num-content {
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 37px;
    .first {
      display: flex;
      .list {
        margin-left: -15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      .tip {
        width: 130px;
        height: 33px;
        font-size: 16px;
        line-height: 32px;
        text-align: center;
        font-weight: 400;
        color: #ffffff;
      }
      .tip-background {
        background: linear-gradient(90deg, #589ecd, #152531);
      }
      .count {
        min-width: 75px;
        text-align: center;
        font-size: 44px;
        font-family: 'impact';
        font-weight: 400;
        color: #ffffff;
      }
      .count2 {
        text-align: center;
        background: linear-gradient(
          0deg,
          #5fcafa,
          #162734,
          rgba(255, 0, 0, 0),
          rgba(255, 0, 0, 0)
        );
      }
      .compare {
        margin-top: 10px;
        font-size: 17px;
        font-weight: 400;
        color: #ffffff;
        .num {
          margin-left: 11px;
          color: #ff5243;
        }
      }
      .compare {
        font-size: 17px;
        font-weight: 400;
        color: #ffffff;
        line-height: 19px;
        .num {
          margin-left: 11px;
        }
      }
      .compare-icon {
        margin-left: 3px;
        display: inline-block;
        width: 14px;
        height: 19px;
      }
      .up {
        background-size: 100% 100%;
        background-image: url('../../assets/icons/upIcon.png');
      }
      .down {
        background-size: 100% 100%;
        background-image: url('../../assets/icons/downIcon.png');
      }
    }
    .border {
      height: 122px;
      width: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(
        180deg,
        rgba(255, 0, 0, 0),
        #86f1d6,
        rgba(255, 0, 0, 0)
      );
    }
    .block {
      width: 5px;
      height: 21px;
      background: #86f1d6;
    }
  }
}
</style>
