<template>
  <div class="middle-content">
    <div
      v-for="(item, index) in list"
      :key="`plane_${index}`"
      @click="clickPlane(item.id, item)"
    >
      <Plane
        :type="item.type"
        :left="item.left"
        :top="item.top"
        :text1="item.text1"
        :text2="item.text2"
        :isActive="item.isActive"
      />
    </div>
  </div>
</template>
<script>
import Bus from '@/bus.js';
import { videoList } from '@/assets/data/data.js';

import Plane from './Plane.vue';
export default {
  components: {
    Plane,
  },
  data() {
    return {
      currentItem: '',
      list: [
        {
          id: 0,
          type: '1',
          left: '300',
          top: '150',
          text1: '城运1号',
          text2: '观前街日常巡航',
          isActive: false,
        },
        {
          id: 1,
          type: '1',
          left: '-100',
          top: '250',
          text1: '城运2号',
          text2: '凤凰街日常巡航',
          isActive: false,
        },
        {
          id: '15',
          type: '2',
          left: '400',
          top: '250',
          text1: '',
          text2: '',
          isActive: false,
        },
        {
          id: 6,
          type: '3',
          left: '800',
          top: '-150',
          text1: '观前街机库',
          text2: '城运1号',
          isActive: false,
        },
        {
          id: '11',
          type: '4',
          left: '1200',
          top: '-400',
          text1: '2021-05-20 12:23:29',
          text2: '观前街附近交通道路拥堵',
          isActive: false,
        },
        {
          id: 2,
          type: '1',
          left: '1200',
          top: '-200',
          text1: '城运3号',
          text2: '石路日常巡航',
          isActive: false,
        },
        {
          id: 7,
          type: '3',
          left: '1800',
          top: '-180',
          text1: '凤凰街机库',
          text2: '城运2号',
          isActive: false,
        },
        {
          id: 8,
          type: '3',
          left: '1600',
          top: '-10',
          text1: '石路机库',
          text2: '城运3号',
          isActive: false,
        },
        {
          id: '12',
          type: '4',
          left: '1100',
          top: '80',
          text1: '2021-05-20 12:23:29',
          text2: '平江路附近识别到人群聚集',
          isActive: false,
        },
      ],
    };
  },
  destroyed() {
    clearInterval();
  },
  methods: {
    clickPlane(index, item) {
      this.list.map((item) => {
        item.isActive = false;
      });
      // 为解决假数据的需求，暂时写的逻辑
      if (index == this.currentItem) {
        this.clickCount = !this.clickCount;
      } else {
        this.currentItem = index;
        this.clickCount = 1;
      }
      if (this.clickCount) {
        this.list.map((list) => {
          if (list.id === item.id) {
            list.isActive = true;
          } else {
            list.isActive = false;
          }
        });
        Bus.$emit('getSource', videoList[index]);
      } else {
        Bus.$emit('getSource', {});
      }
    },
  },
  created() {
    // 根据视频资源判断
    Bus.$on('getSource', (target) => {
      if (!target.id) {
        this.list.map((item) => {
          item.isActive = false;
        });
      }
    });
  },
};
</script>
<style lang="less" scoped>
.middle-content {
  width: 1911px;
  color: #fff;
}
</style>
