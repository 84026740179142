<template>
  <div class="main">
    <list-of-URV />
    <video-content style="margin: 0 51px 0 14px" />
  </div>
</template>
<script>
import ListOfURV from './ListOfURV.vue';
import VideoContent from './VideoContent.vue';
export default {
  components: { ListOfURV, VideoContent },
};
</script>
<style lang="less" scoped>
.main {
  display: flex;
}
</style>
