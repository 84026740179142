<template>
  <div class="video-content">
    <list-of-video
      listIcon="icon-video"
      listName="实时视频"
      :data="[uavList[0], uavList[1], uavList[2]]"
    />
    <list-of-video
      listIcon="icon-video"
      listName="历史回放"
      :data="[uavList[3], uavList[4], uavList[5]]"
    />
    <list-of-video
      listIcon="icon-video"
      listName="机库视频"
      :data="[garageList[0], garageList[1], garageList[2]]"
    />

    <transition name="full-screen-play">
      <full-screen-play
        class="full-screen-play"
        v-if="source.path"
        :videoObj="{
          name: source.name,
          path: require('../../assets/videos/' + source.path),
        }"
      />
    </transition>
  </div>
</template>
<script>
import ListOfVideo from './ListOfVideo.vue';
import FullScreenPlay from './FullscreenPlay.vue';
import { videoList } from '@/assets/data/data.js';
import Bus from '@/bus.js';
// 获取数据
export default {
  name: 'VideoContent',
  data() {
    return {
      source: { name: '', path: '' },
      show: false,
      videoList,
      uavList: [],
      garageList: [],
    };
  },
  components: {
    ListOfVideo,
    FullScreenPlay,
  },
  methods: {
    // 区分是无人机视频还是机库视频
    dealList(videoList) {
      videoList.map((item) => {
        if (item.type === 'uav') {
          this.uavList.push(item);
        } else {
          this.garageList.push(item);
        }
      });
    },
  },
  mounted() {
    this.dealList(this.videoList);
  },
  created() {
    // 根据视频资源判断
    Bus.$on('getSource', (target) => {
      this.source = target;
    });
  },
};
</script>
<style lang="less" scoped>
.video-content {
  width: 2022px;
  height: 1261px;
  border: 1px dashed #213a4d;
  position: relative;
  background-color: rgba(35, 58, 77, 0.5);
  .full-screen-play {
    position: absolute;
    top: 60px;
    left: 16px;
    z-index: 1001;
  }
  // 过渡动画效果
  .full-screen-play-enter-active {
    transition: opacity 1s;
  }
  .full-screen-play-enter {
    opacity: 0;
  }
  .full-screen-play-leave-active {
    transition: opacity 0.5s;
  }
  .full-screen-play-leave-to {
    opacity: 0;
  }
  .full-screen-play-enter-to {
    opacity: 1;
  }
}
</style>
