<template>
  <table class="list-header">
    <tr>
      <td>名称</td>
      <td>机型</td>
      <td>状态</td>
      <td>直播</td>
    </tr>
  </table>
</template>
<script>
export default {
  name: 'ListHeader',
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
@bg: #0d1924;
.list-header {
  width: 520px;
  height: 55px;
  line-height: 55px;
  background-color: rgba(13, 25, 36, 0.5);
  color: #fff;
  font-size: 22px;
  border-radius: 2px;
  display: flex;
  td:nth-of-type(1) {
    padding-left: 64px;
    width: 162px;
  }
  td:nth-of-type(2) {
    width: 128px;
  }
  td:nth-of-type(3) {
    width: 66px;
  }
  td:td:nth-of-type(4) {
    width: 66px;
  }
}
</style>
