<template>
  <div class="list-body">
    <table class="list" v-for="(item, index) in data" :key="index">
      <tr class="row" style="margin-top: 44px">
        <td>
          <img src="@/assets/images/icon-uav2.png" />
        </td>
        <td>
          {{ item.uav.name }}
        </td>
        <td>{{ item.uav.model }}</td>
        <td>
          <span :class="getStatusClass(item.uav.status)">{{
            getStatus(item.uav.status)
          }}</span>
        </td>
        <td>
          <lp-switch
            :disabled="!item.uav.live"
            :id="item.uav.id"
            :checked="item.garage.live"
            @handleClick="handleClick"
          />
        </td>
      </tr>
      <tr class="row" style="margin-top: 30px">
        <td>
          <img src="@/assets/images/icon-garage.png" />
        </td>
        <td>
          {{ item.garage.name }}
        </td>
        <td>{{ item.garage.model }}</td>
        <td>
          <span :class="getStatusClass(item.uav.status)">{{
            getStatus(item.garage.status)
          }}</span>
        </td>
        <td>
          <lp-switch
            :disabled="!item.garage.live"
            :id="item.garage.id"
            :checked="item.garage.live"
            @handleClick="handleClick"
          />
        </td>
      </tr>
      <div class="line"></div>
    </table>
  </div>
</template>
<script>
import Bus from '@/bus.js';
import LpSwitch from '@/components/public/LpSwitch.vue';
export default {
  name: 'ListBody',
  data() {
    return {};
  },
  components: {
    LpSwitch,
  },
  props: ['data'],
  methods: {
    getStatus(code) {
      if (code == 0) {
        return '离线';
      }
      if (code == 1) {
        return '在线';
      }
      if (code == 2) {
        return '维护中';
      }
    },
    // 判断无人机状态
    getStatusClass(code) {
      if (code == 0) {
        return 'offline';
      }
      if (code == 1) {
        return 'online';
      }
      if (code == 2) {
        return 'servicing';
      }
    },
    // switch点击事件
    handleClick(e, id) {
      Bus.$emit('switchVideo', [e, id]);
    },
  },
};
</script>
<style lang="less" scoped>
.list-body {
  .list {
    height: 171px;
    width: 520px;
    margin: 0 13px;

    background-color: rgba(13, 25, 36, 0.5);
    .row {
      display: flex;
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
      color: #fff;
      img {
        width: 24px;
        height: 24px;
      }
      td:nth-of-type(1) {
        padding-left: 32px;
        margin-right: 12px;
      }
      td:nth-of-type(2) {
        width: 162px;
      }
      td:nth-of-type(3) {
        width: 128px;
      }
      td:nth-of-type(4) {
        width: 66px;
      }
      td:td:nth-of-type(5) {
        width: 66px;
      }
    }
    .line {
      width: 90%;
      margin-left: 5%;
      height: 1px;
      margin-top: 46px;
      background: -webkit-linear-gradient(
        left,
        rgba(13, 25, 36, 0.5) -4%,
        #fff 50%,
        rgba(13, 25, 36, 0.5) 104%
      );
    }
  }
  .list:nth-last-child(1) {
    .line {
      margin-top: 46px;
      background: transparent;
    }
  }
  // status
  // 在线
  .online {
    color: #72f5af;
  }
  // 离线
  .offline {
    color: #aaa;
  }
  // 维护中
  .servicing {
    color: #ff5243;
  }
}
</style>
