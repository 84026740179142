<template>
  <div class="title">
    <img
      :src="require(`../../assets/icons/${icon}.png`)"
      alt="icon"
      :style="{ width: `${iconWidth}px` }"
    />
    <span class="text">{{ title }}</span>
    <div class="title-bg"></div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '标题',
    },
    icon: {
      type: String,
    },
    iconWidth: {
      type: String,
      default: '33',
    },
  },
};
</script>
<style lang="less" scoped>
.title {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 47px;
  border-bottom: 2px transparent solid;
  border-image: linear-gradient(to right, #b3c7fa, rgba(255, 0, 0, 0)) 10 10;
  img {
    margin-right: 22px;
    z-index: 2;
  }
  .text {
    line-height: 39px;
    text-shadow: 0px 3px 1px rgba(1, 7, 11, 0.63);
    font-size: 32px;
    color: #ffffff;
    z-index: 2;
  }
  .title-bg {
    position: absolute;
    left: 0px;
    bottom: 6px;
    width: 100%;
    height: 21px;
    background: linear-gradient(
      90deg,
      rgba(179, 199, 250, 0.39),
      rgba(255, 0, 0, 0)
    );
    z-index: 1;
  }
}
</style>
