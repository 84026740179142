<template>
  <div class="list-of-urv">
    <lp-title icon="icon-uav" title="无人机列表" style="margin-left: 14px" />
    <list-header class="list-header" style="margin-top: 28px" />
    <list-body class="list-body" style="margin-top: 4px" :data="urvList" />
  </div>
</template>
<script>
import LpTitle from '@/components/public/LpTitle.vue';
import ListHeader from './ListHeader.vue';
import ListBody from './ListBody.vue';

import { urvList } from '@/assets/data/data.js';

export default {
  name: 'ListOfURV',
  data() {
    return {
      urvList,
    };
  },
  components: {
    LpTitle,
    ListHeader,
    ListBody,
  },
};
</script>
<style lang="less" scoped>
.list-of-urv {
  width: 548px;
  height: 1260px;
  background: #1b2e3d;
  border: 1px dashed #213a4d;
  background-color: rgba(35, 58, 77, 0.5);
  .list-header {
    margin: 0 auto;
  }
  .list-body {
    margin: 0 auto;
  }
}
</style>
